import React from 'react';

import { Route, Routes } from 'react-router-dom';

import AiCreditAssistant from './pages/AiCreditUsage/AiCreditAssistant';
import AiCreditAzure from './pages/AiCreditUsage/AiCreditAzure';
import AiCreditSpeech from './pages/AiCreditUsage/AiCreditSpeech';
import AiCreditUsageAmount from './pages/AiCreditUsage/AiCreditUsageAmount';
import Homepage from './pages/Homepage/Homepage';
import LibraryUsers from './pages/LibraryUsers';
import LoginPage from './pages/Login/LoginPage';
import NoMatch from './components/NoMatch';
import RequireAuth from './components/RequireAuth';
import UsersIndexPage from './pages/Users/UsersIndexPage';
import UsersShowPage from './pages/Users/UsersShowPage';
import AiConfig from './pages/AIConfig/AiConfig';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />

      <Route path='/' element={<RequireAuth />}>
        <Route path='/' element={<Homepage />} />
        <Route path='/ai-reports'>
          <Route path='/ai-reports/azure' element={<AiCreditAzure />} />
          <Route path='/ai-reports/speech' element={<AiCreditSpeech />} />
          <Route path='/ai-reports/assistant' element={<AiCreditAssistant />} />
          <Route path='/ai-reports/by-user' element={<AiCreditUsageAmount />} />
        </Route>
        <Route path='/ai-config'>
          <Route path='/ai-config/dify' element={<AiConfig />} />
        </Route>
        <Route path='/library'>
          <Route path='/library/users' element={<LibraryUsers />} />
        </Route>
        <Route path='/users'>
          <Route index element={<UsersIndexPage />} />
          <Route path='/users/:userId' element={<UsersShowPage />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Route>

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}
