import React, { ReactNode } from 'react';
import type { MenuProps } from 'antd';
import { Button, Layout, Menu, theme, Typography } from 'antd';

import { ReactComponent as Logo } from 'assets/logo_light.svg';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import styles from './AppLayout.module.scss';
import { useAuth } from '../../contexts/auth';

const { Text } = Typography;

const { Header, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export default function AppLayout({ children }: { children: ReactNode }) {
  const { user, isAdmin, logout } = useAuth();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items: MenuItem[] = [
    {
      key: 'users',
      label: <Link to='/users'>Usuários</Link>,
    },
    {
      key: 'LibraryUsers',
      label: <Link to='/library/users'>Biblioteca</Link>,
    },
  ];
  if (isAdmin()) {
    items.push({
      key: 'ai-reports',
      label: 'Relatórios de IA',
      children: [
        {
          key: 'ai-reports/azure',
          label: <Link to='/ai-reports/azure'>Azure</Link>,
        },
        {
          key: 'ai-reports/speech',
          label: <Link to='/ai-reports/speech'>Speech</Link>,
        },
        {
          key: 'ai-reports/assistant',
          label: <Link to='/ai-reports/assistant'>Assistant</Link>,
        },
        {
          key: 'ai-reports/by-user',
          label: <Link to='/ai-reports/by-user'>Por usuário</Link>,
        },
      ],
    });
    items.push({
      key: 'Dify',
      label: <Link to='/ai-config/dify'>Dify</Link>,
    });
  }

  return (
    <Layout className={styles.Layout}>
      <Header className={styles.Header}>
        <Link to='/' className={styles.HomepageLink}>
          <Logo className={styles.LeoLogo} />
        </Link>
        <Menu
          theme='dark'
          mode='horizontal'
          items={items}
          className={styles.Menu}
        />
        <div>
          <Text style={{ color: 'white' }}>{user.name}</Text>
          <Button
            type='primary'
            danger
            onClick={() => {
              logout();
            }}
            style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
            size='small'
          >
            <LogoutOutlined />
          </Button>
        </div>
      </Header>
      <Content
        className={styles.Content}
        style={{
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {children}
      </Content>
    </Layout>
  );
}
